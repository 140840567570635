import styled from 'styled-components';

export const HeroCorner = styled.header`
  color: ${(props) => props.theme.palette.white};
  padding: 40px 0;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: flex-end;

  min-height: 300px;
  @media (min-width: 768px) {
    min-height: 600px;
  }
`;

export const CornerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Corner = styled.div`
  border-width: 3px 3px 0 0;
  border-style: solid;
  padding: 24px 32px;
  border-color: inherit;
`;

export const CornerHeading = styled.span`
  font-size: 4rem;
  margin: 0;
  line-height: 1.1;
`;

export const CornerSubHeading = styled.span`
  font-size: 2.4rem;
  border-width: 3px 3px 0 0;
  line-height: 1.3;
  margin: 32px 0 0;
  font-weight: 400;
  display: block;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 32px;
`;

export const Title = styled.span`
  font-weight: 700;
  margin: 0;
  font-size: 40px;
  line-height: 65px;
`;

export const Em = styled.span`
  display: block;
  font-size: 2.2rem;
  font-weight: 400;
  margin: 0;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  max-width: 1300px;
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  z-index: 2;
`;


export const CTA = styled.div`
  margin-top: 0;
`;

export const BackgroundContainer = styled.div`
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  
  // image wrap elem
  > span {
    height: 100%;
  }

  img:not([data-lqip]) {
    width: 100%;
    min-height: 100%;
  }
  
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    opacity: .5;
    background-image: linear-gradient(
      to left,
      ${(props) => props.theme.palette.shade},
      ${(props) => props.theme.palette.primary}
    );
  }
`;
