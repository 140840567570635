import React from 'react';
import PropTypes from 'prop-types';

import * as Generic from './styles';
import * as TopBottom from './top-bottom';
import * as LeftRight from './left-right';

const StylesMap = {
  'top-bottom': TopBottom,
  'left-right': LeftRight,
};

const HeroCorner = ({
  heading,
  subheading,
  em,
  title,
  action,
  background,
  variant,
}) => {
  const S = StylesMap[variant];

  return (
    <S.HeroCorner>
      <Generic.BackgroundContainer>
        {
          background
        }
      </Generic.BackgroundContainer>
      <S.Wrapper>
        <S.CornerContainer>
          <Generic.Corner>
            {
              heading && (
                <Generic.CornerHeading>{heading}</Generic.CornerHeading>
              )
            }
            {
              subheading && (
                <Generic.CornerSubHeading>{subheading}</Generic.CornerSubHeading>
              )
            }
          </Generic.Corner>
        </S.CornerContainer>
        <S.InfoContainer>
          {
            em && (
              <Generic.Em>{em}</Generic.Em>
            )
          }
          {
            title && (
              <Generic.Title>{title}</Generic.Title>
            )
          }
          {
            action && (
              <Generic.CTA>{action}</Generic.CTA>
            )
          }
        </S.InfoContainer>
      </S.Wrapper>
    </S.HeroCorner>
  );
};

HeroCorner.propTypes = {
  background: PropTypes.node.isRequired,

  heading: PropTypes.string,
  subheading: PropTypes.string,

  title: PropTypes.string,
  em: PropTypes.string,

  action: PropTypes.node,
  variant: PropTypes.oneOf(['top-bottom', 'left-right']),
};

HeroCorner.defaultProps = {
  variant: 'top-bottom',
};

export default HeroCorner;
