import styled from 'styled-components';

import {
  HeroCorner as HC,
  Wrapper as W,
  CornerContainer as CC,
  Corner,
  InfoContainer as IC,
  Title,
} from './styles';

export const HeroCorner = styled(HC)`
  align-items: stretch;
`

export const Wrapper = styled(W)`
  flex-direction: column;
  height: auto;
`;

export const CornerContainer = styled(CC)`
  flex-direction: row;
  justify-content: flex-end;

  ${Corner} {
    flex: 0 1 45%;
  }  
`;

export const InfoContainer = styled(IC)`
  ${Title} {
    font-size: 4rem;
  }
`;

