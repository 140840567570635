import styled from 'styled-components';
import { breakpoint } from '../../ThemeProvider';

import {
  HeroCorner as HC,
  Wrapper as W,
  CornerContainer as CC,
  InfoContainer as IC,
  Title,
} from './styles';

export const HeroCorner = styled(HC)`
  align-items: flex-end;
`;

export const Wrapper = styled(W)`
  flex-direction: column;
  justify-content: flex-end;
  
  ${breakpoint.md`
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
  `};  
`;

export const CornerContainer = styled(CC)`
  flex: 0 1 35%;
`;

export const InfoContainer = styled(IC)`
  flex: 0 1 25%;
  align-self: flex-end;
  
  ${Title} {
    font-size: 2.4rem;
  }
`;
